$default: #f9f9ff;
$primary: $primary-color;
$success: #4cd3e3;
$info   : #38a4ff;
$warning: #f4e700;
$danger: #f44a40;
$link: #f9f9ff;
$disable: (#222222, .3);
.sample-text-area {
	background: $white;
	padding: 100px 0 70px 0;
}
.text-heading {
	margin-bottom: 30px;
	font-size: 24px;
}
b, i, sup, sub, u, del {
	color: $primary;
}
h1 {
	font-size: 40px;
}
h2 {
	font-size: 36px;
}
h3 {
	font-size: 24px;
}
h4 {
	font-size: 18px;
}
h5 {
	font-size: 16px;
}
h6 {
	font-size: 14px;
}
// h1, h2, h3, h4, h5, h6 {
// 	line-height: 1.5em;
// }
// .typography {
// 	h1, h2, h3, h4, h5, h6 {
// 		color: $text-color;
// 	}
// } 