html{
	scroll-behavior: smooth;
}

body {
	position: relative; 
}
.default-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9;
}
.menu-bar {
	cursor: pointer;
	span {
		color: $black;
		font-size: 24px;
	}
}

@media(max-width: 992px){
	.navbar-nav {
	    height: auto;
	    max-height: 400px;
	    overflow-x: hidden;
	}
}

.main-menubar{
	display:none!important;
}

@include media-breakpoint-down(md) {
	.main-menubar{
		display:block !important;
	}
}

.navbar-brand{
	span{
		color: $black;
	}
}



.navbar-nav{

	.nav-link{
		display: inline;
	}
	
	a{  
		text-transform: capitalize; 
		font-family: Raleway;
		font-weight:600;
		color:$black;
		padding: 20px;
		&:hover{
			color:$primary-color;
		}
	}
	@media(max-width:992px){
		margin-top:10px;
		a{
			padding:0;
		}
		li{
			padding:15px 0;
		}
	}
}



